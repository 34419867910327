import { Navigate, Route, Routes, useParams } from "react-router-dom";
import * as React from "react";
import styled from "styled-components";
import { useGetOneFormateur } from "@mbj-front-monorepo/data-access";
import { InternDesktopLayout, MbjHeading, MbjLink, MbjLoading, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import { FaUserCircle } from "react-icons/fa";
import { NotFound } from "../../../../components/NotFound/NotFound";
import { Informations } from "./Informations/Informations";
import { Competences } from "./Competences/Competences";
import { FormateurCv } from "./CV/CV";
import { Expertises } from "./Expertises/Expertises";

interface OneFormateurRooterCtrlProps {
  className?: string;
}

const OneFormateurRooterCtrl = (props: OneFormateurRooterCtrlProps) => {
  const { id } = useParams();
  const FormateurQuery = useGetOneFormateur(id ? parseInt(id) : 0);
  return (
    <Routes>
      <Route
        element={
          <InternDesktopLayout
            type="OF"
            mbjSectionMenu={
              <MbjSectionMenu
                className={"custom-sectionMenu " + props.className}
                otherContent={
                  <div className={"custom-content-menu"}>
                    {FormateurQuery.data ? (
                      <>
                        <FaUserCircle className={"icon-profile-menu"} />
                        <MbjHeading marginIn={"0"} themeColor={"primary"} level={"h2"}>
                          {`${FormateurQuery.data?.nom?.toUpperCase()} ${FormateurQuery.data?.prenom}`}
                        </MbjHeading>
                      </>
                    ) : (
                      <MbjLoading noMargin={true} height={"25px"} />
                    )}
                  </div>
                }
              >
                <MbjLink to="informations">Informations</MbjLink>
                <MbjLink to="cv">CV</MbjLink>
                <MbjLink to="competences">Compétences</MbjLink>
                <MbjLink to="expertises">Expertises</MbjLink>
              </MbjSectionMenu>
            }
          />
        }
      >
        <Route path={""} element={<Navigate to={"informations"} />} />
        <Route path={"informations"} element={<Informations />} />
        <Route path={"cv"} element={<FormateurCv />} />
        <Route path={"competences/*"} element={<Competences />} />
        <Route path={"expertises"} element={<Expertises />} />
        <Route path={"*"} element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export const OneFormateurRooter = styled(OneFormateurRooterCtrl)((props) => ({
  minHeight: "fit-content",
  justifyContent: "space-between",
}));
