import styled from "styled-components";
import { MbjBlockMenu, MbjDirectLink } from "../../../molecules/MbjMenu/MbjMenuLinks";
import { useMemo, useState } from "react";
import { MbjSideLink } from "./MbjSideLink";
import { useGetMemberMe } from "@mbj-front-monorepo/data-access";

interface MbjBlockMenuCtrlProps {
  className?: string;
  Block: MbjBlockMenu;
  titleOpen: string;
  onSetTitleOpen: (title: string) => void;
  type?: string;
}

const MbjBlockMenuCtrl = (props: MbjBlockMenuCtrlProps) => {
  const memberQuery = useGetMemberMe();
  const BlockFiltered = useMemo(() => {
    let myBlocks: MbjDirectLink[] = [];
    if ((props.type === "Particulier" || props.type === "OF") && props.Block.subMenus) {
      myBlocks = props.Block.subMenus;
      console.log(myBlocks);
    } else if (memberQuery.data) {
      const nivoAccess = memberQuery.data?.niveauAcces || ["-1"];
      const nivoAccessNb = nivoAccess.map((x) => parseInt(x));
      props.Block.subMenus?.forEach((b) => {
        console.log(b);
        if (!b.nivo) {
          myBlocks.push(b);
        } else {
          let nbCommun = 0;
          b.nivo.forEach((i) => {
            if (nivoAccessNb.indexOf(i) !== -1) {
              nbCommun++;
            }
          });
          if (nbCommun > 0) {
            myBlocks.push(b);
          }
        }
      });
    }

    return myBlocks;
  }, [props.Block, memberQuery.data]);
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`block_menu_mbj ${props.className}  ${props.titleOpen === props.Block.libelle ? "opened" : "closed"}`}
    >
      <div
        className={`title_section ${props.titleOpen === props.Block.libelle ? "opened" : "closed"}`}
        onClick={() => {
          setOpen((o) => !o);
          props.onSetTitleOpen(props.Block.libelle);
        }}
      >
        <div className="icon_place">{props.Block.icon}</div>
        <div className="lib_place">{props.Block.libelle}</div>
      </div>
      <div className={`content_accordion`} aria-expanded={!(props.titleOpen === props.Block.libelle)}>
        {BlockFiltered?.map((item, idx) => (
          <MbjSideLink Link={item} key={`oneMenu${idx}`} />
        ))}
      </div>
    </div>
  );
};

export const MbjBlockMenuCpt = styled(MbjBlockMenuCtrl)`
  margin-bottom: 12px;
  &.opened {
    background: ${(props) => props.theme.primary};
  }
  .title_section {
    font-size: 17px;
    display: flex;
    justify-content: flex-start;
    color: white;
    gap: 8px;
    padding: 0.5rem;
    &.opened {
      background: ${(props) => props.theme.primaryDarken};
    }
    &:hover {
      cursor: pointer;
      background: ${(props) => props.theme.primaryLighten};
    }
  }
  .content_accordion {
    width: 100%;
    overflow: hidden;
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
  }
  .content_accordion[aria-expanded="true"] {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
`;
